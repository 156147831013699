import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Button } from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';
import MemberTree from './components/MemberTree';
import MemberTable from './components/MemberTable';
import MemberModal from './components/MemberModal';
import { calculateCommission, calculateBranchSales } from './utils/commissionCalculator';
import { generateRandomMembers } from './utils/utils'; // Import hàm random
import SystemOverviewCard from './components/SystemOverviewCard';
import ProductInfo from './components/ProductInfo';
import { dataTree, PRODUCT_NAME, PRODUCT_PRICE, PRODUCT_PV, PRODUCT_IMAGE_URL } from './constants';
const { Header, Content } = Layout;

const INITIAL_TREE_DATA = [
  {
    key: 'Admin',
    title: 'Admin',
    sales: 0,
    pv: 0,
    commission: 0,
    children: [],
  },
];

const App = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newMemberCount, setNewMemberCount] = useState(1); // Số lượng thành viên muốn thêm
  const [selectedOption, setSelectedOption] = useState("randomSales"); // Tùy chọn mặc định
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [salesAmount, setSalesAmount] = useState(0);
  const [treeData, setTreeData] = useState([dataTree]);

  const [flattenedData, setFlattenedData] = useState([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  
  const calculateMemberCounts = (node) => {
    const level1Count = node.children ? node.children.length : 0;
    let level2Count = 0;

    if (node.children) {
      node.children.forEach((child) => {
        if (child.children) {
          level2Count += child.children.length;
        }
      });
    }

    return { level1Count, level2Count };
  };

  const calculateSystemOverview = (nodes) => {
    let members = 0;
    let sales = 0;
    let commission = 0;

    const traverseTree = (nodes) => {
      nodes.forEach((node) => {
        // Duyệt con trước
        if (node.children && node.children.length > 0) {
          traverseTree(node.children);
        }
        
        members += 1;
        sales += node.sales || 0;
        if (node.commission) {
          console.log(`${node.key} commission: ${node.commission.toLocaleString()}₫`);
          commission += node.commission;
        }
      });
    };

    traverseTree(nodes);
    console.log(`Total members: ${members}`);
    console.log(`Total sales: ${sales.toLocaleString()}₫`);
    console.log(`Total commission: ${commission.toLocaleString()}₫`);
    
    setTotalMembers(members);
    setTotalSales(sales);
    setTotalCommission(commission);
  };

  const updateTreeDataWithCounts = (data) => {
    return data.map((node) => {
      const { level1Count, level2Count } = calculateMemberCounts(node);
      let updatedTitle = node.title.split(" ")[0];

      if (level1Count > 0 || level2Count > 0) {
        updatedTitle += ` (${level1Count} Lv1, ${level2Count} Lv2)`;
      }

      const updatedNode = {
        ...node,
        title: updatedTitle, // Cập nhật tiêu đề mới
        children: node.children ? updateTreeDataWithCounts(node.children) : [],
      };
      return updatedNode;
    });
  };

  const flattenTree = (nodes, parent = null, level = 0) => {
    return nodes.reduce((acc, node) => {
      const { children, ...nodeWithoutChildren } = node;
      const flatNode = {
        ...nodeWithoutChildren,
        parent: parent ? parent.title : null,
        level,
      };
      acc.push(flatNode);
      if (children) {
        acc.push(...flattenTree(children, node, level + 1));
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    const updatedTree = updateTreeDataWithCounts([...treeData]);
    setFlattenedData(flattenTree(updatedTree));
    calculateSystemOverview(updatedTree);
  }, []);

  useEffect(() => {
    const updatedTree = updateTreeDataWithCounts([...treeData]);
    setTreeData(updatedTree);
  }, []);

  const handleCalculateCommission = () => {
    const updatedTreeData = calculateCommissionForTree(treeData);
    setTreeData([...updatedTreeData]);
    
    // Cập nhật lại tổng quan hệ thống
    calculateSystemOverview(updatedTreeData);
    
    // Cập nhật lại flattenedData
    const updatedTreeWithCounts = updateTreeDataWithCounts([...updatedTreeData]);
    setFlattenedData(flattenTree(updatedTreeWithCounts));
  };

  const handleAddNewMembers = (count) => {
    const newMembers = generateRandomMembers(newMemberCount, PRODUCT_PRICE, PRODUCT_PV, selectedOption, salesAmount);
  
    const addMembersToTree = (data, key) => {
      return data.map((item) => {
        if (item.key === key) {
          return { ...item, children: [...(item.children || []), ...newMembers] };
        }
        if (item.children) {
          return { ...item, children: addMembersToTree(item.children, key) };
        }
        return item;
      });
    };
  
    const updatedTree = addMembersToTree(treeData, selectedNodeKey);
    setTreeData(updatedTree);
    
    // Cập nhật flattenedData
    setFlattenedData(flattenTree(updatedTree));
    
    // Cập nhật các thông số tổng quan
    calculateSystemOverview(updatedTree);
  
    // Reset các state
    setIsModalVisible(false);
    setNewMemberCount(1);
    setSelectedOption("randomSales");
    setSalesAmount(0);
  };
  
  const handleAddMemberClick = (key) => {
    setSelectedNodeKey(key);
    setIsModalVisible(true); // Mở modal để nhập số lưng thành viên cần thêm
  };

  const handleReset = () => {
    setTreeData(INITIAL_TREE_DATA);
    setFlattenedData(flattenTree(INITIAL_TREE_DATA));
    setTotalMembers(1);
    setTotalSales(0);
    setTotalCommission(0);
    setSelectedNodeKey(null);
    setIsModalVisible(false);
    setNewMemberCount(1);
    setSelectedOption("randomSales");
    setSalesAmount(0);
  };

  const handleUpdateMember = (key, updates) => {
    const updateTreeNode = (nodes) => {
      return nodes.map(node => {
        if (node.key === key) {
          return { ...node, ...updates };
        }
        if (node.children) {
          return {
            ...node,
            children: updateTreeNode(node.children)
          };
        }
        return node;
      });
    };

    const updatedTree = updateTreeNode([...treeData]);
    setTreeData(updatedTree);
    
    // Cập nhật lại flattenedData và system overview
    const updatedTreeWithCounts = updateTreeDataWithCounts(updatedTree);
    setFlattenedData(flattenTree(updatedTreeWithCounts));
    calculateSystemOverview(updatedTreeWithCounts);
  };

  const calculateCommissionForTree = (treeData) => {
    const processNode = (node) => {
      // Nếu không có node, return null
      if (!node) return null;

      // Tạo bản sao của node để không ảnh hưởng đến dữ liệu gốc
      let updatedNode = { ...node };

      // Nếu có children, xử lý đệ quy cho từng child
      if (node.children && node.children.length > 0) {
        updatedNode.children = node.children.map(child => processNode(child));
      }

      // Tính hoa hồng cho node hiện tại
      try {
        const result = calculateCommission(updatedNode);
        
        // Lưu kết quả vào node
        updatedNode.commission = result.total;
        updatedNode.commissionDetails = {
          hh1: result.hh1,
          hh2: result.hh2,
          hh3: result.hh3,
          hh4: result.hh4
        };

        // Log kết quả để debug
        console.log(`${updatedNode.title}:`);
        console.log(`- Doanh số: ${updatedNode.sales?.toLocaleString()}₫`);
        console.log(`- HH1: ${result.hh1.toLocaleString()}₫`);
        console.log(`- HH2: ${result.hh2.toLocaleString()}₫`);
        console.log(`- HH3: ${result.hh3.toLocaleString()}₫`);
        console.log(`- HH4: ${result.hh4.toLocaleString()}₫`);
        console.log(`- Tổng HH: ${result.total.toLocaleString()}₫`);
        console.log('-------------------');

      } catch (error) {
        console.error(`Lỗi tính hoa hồng cho ${updatedNode.title}:`, error);
        updatedNode.commission = 0;
        updatedNode.commissionDetails = { hh1: 0, hh2: 0, hh3: 0, hh4: 0 };
      }

      return updatedNode;
    };

    // Xử lý toàn bộ cây
    return treeData.map(node => processNode(node));
  };

  return (
    <Layout>
      <Header style={{ color: 'white', fontSize: '24px', padding: '0 16px', display: 'flex', alignItems: 'center' }}>
        Commission Calculator
      </Header>        
      <Content style={{ padding: '16px' }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={4} style={{ marginBottom: '20px' }}> {/* Cột 1 chiếm 30% */}
            <ProductInfo />
            <SystemOverviewCard 
              totalMembers={totalMembers} 
              totalSales={totalSales}
              totalCommission={totalCommission}
            />
            <Button
              type="primary"
              onClick={handleCalculateCommission}
              style={{ marginTop: '20px', width: '100%' }}
              icon={<CalculatorOutlined />}
            >
              Tính hoa hồng
            </Button>
            <Button
              type="default"
              danger
              onClick={handleReset}
              style={{ marginTop: '10px', width: '100%' }}
            >
              Reset Data
            </Button>
          </Col>
          <Col xs={24} md={16}> {/* Cột 3 chiếm 40% */}
            <MemberTable 
              flattenedData={flattenedData} 
              onAddMemberClick={handleAddMemberClick}
              onUpdateMember={handleUpdateMember}
            />
          </Col>
          <Col xs={24} md={4} style={{ marginBottom: '20px' }}> {/* Cột 2 chiếm 30% */}
            <MemberTree treeData={treeData} setSelectedNodeKey={setSelectedNodeKey} setIsModalVisible={setIsModalVisible} />
          </Col>
        </Row>
        <MemberModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          newMemberCount={newMemberCount}
          setNewMemberCount={setNewMemberCount}
          handleAddNewMembers={handleAddNewMembers}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption} 
          setSalesAmount={setSalesAmount}
        />
      </Content>
    </Layout>
  );
};

export default App;