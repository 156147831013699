// commissionCalculator.js

const commissionRates = [
  { minAmount: 29000000, rate: 0.21 },    
  { minAmount: 20000000, rate: 0.20 },    
  { minAmount: 13000000, rate: 0.18 },    
  { minAmount: 8000000, rate: 0.16 },     
  { minAmount: 3500000, rate: 0.14 },      
  { minAmount: 1500000, rate: 0.12 },      
  { minAmount: 0, rate: 0.10 },            
];
// Hàm lấy hệ số hoa hồng dựa vào doanh số
const getCommissionRate = (amount) => {
  for (const tier of commissionRates) {
    if (amount >= tier.minAmount) return tier.rate;
  }
  return 0.10;
};
// Hàm tính doanh số nhánh
const calculateBranchSales = (member) => {
  if (!member) return 0;
  
  let total = member.sales;
  
  if (member.children) {
    member.children.forEach(child => {
      if (!isBranch21Percent(child)) {
        total += calculateBranchSales(child);
      }
    });
  }
  return total;
};

const getBranchRate = (member) => {
  const branchTotal = calculateBranchSales(member);
  return getCommissionRate(branchTotal);
};

const isBranch21Percent = (member) => {
  return getBranchRate(member) >= 0.21;
};

const calculateHH1 = (member, f1List) => {
  const personalSales = member.sales;
  let f1SalesNot21 = 0;
  
  console.log('\n1. Tính HH1:');
  console.log(`- DS cá nhân: ${personalSales.toLocaleString()}`);
  
  f1List.forEach(f1 => {
    if (!isBranch21Percent(f1)) {
      f1SalesNot21 += f1.sales;
      console.log(`- Cộng DS ${f1.title}: ${f1.sales.toLocaleString()} (nhánh <21%)`);
    } else {
      console.log(`- Bỏ qua ${f1.title} (nhánh ≥21%)`);
    }
  });
  
  const totalSales = personalSales + f1SalesNot21;
  const rate = getCommissionRate(totalSales);
  return totalSales * rate;
};

const calculateHH2 = (member, f1List, f2List) => {
  let f2Commission = 0;
  
  console.log('\n2. Tính HH2:');
  
  f2List.forEach(f2 => {
    const f1Parent = f1List.find(f1 => 
      f1.children?.some(child => child.title === f2.title)
    );
    
    if (f1Parent && !isBranch21Percent(f1Parent) && !isBranch21Percent(f2)) {
      const f2Rate = getCommissionRate(f2.sales);
      const commission = f2.sales * f2Rate;
      f2Commission += commission;
      
      console.log(`- ${f2.title} (con của ${f1Parent.title}):`);
      console.log(`  DS: ${f2.sales.toLocaleString()} × ${(f2Rate*100).toFixed(1)}% = ${commission.toLocaleString()}`);
    }
  });
  
  return f2Commission * 0.03;
};

const calculateHH3AndHH4 = (member, f1List) => {
  let hh3 = 0;
  let hh4 = 0;
  
  console.log('\n3. Tính HH3 và HH4:');
  
  f1List.forEach(f1 => {
    if (!isBranch21Percent(f1)) {
      const f1Rate = getCommissionRate(f1.sales);
      const commission = f1.sales * f1Rate;
      hh3 += commission;
      console.log(`- Trừ HH3 ${f1.title}: ${f1.sales.toLocaleString()} × ${(f1Rate*100).toFixed(1)}% = ${commission.toLocaleString()}`);
    } else {
      const f1Commission = calculateCommission(f1).total;
      const bonus = f1Commission * 0.06;
      hh4 += bonus;
      console.log(`- Cộng HH4 ${f1.title}: ${f1Commission.toLocaleString()} × 6% = ${bonus.toLocaleString()}`);
    }
  });
  
  return { hh3, hh4 };
};

const calculateCommission = (member) => {
  console.log(`\n========== Tính hoa hồng cho ${member.title} ==========`);
  
  const f1List = member.children || [];
  const f2List = f1List.flatMap(f1 => f1.children || []);
  
  console.log('\nThông tin doanh số nhánh:');
  f1List.forEach(f1 => {
    const f1Total = calculateBranchSales(f1);
    console.log(`${f1.title}: ${f1Total.toLocaleString()} (${isBranch21Percent(f1) ? '≥21%' : '<21%'})`);
    f1.children?.forEach(f2 => {
      const f2Total = calculateBranchSales(f2);
      console.log(`  └─ ${f2.title}: ${f2Total.toLocaleString()}`);
    });
  });
  
  const hh1 = calculateHH1(member, f1List);
  const hh2 = calculateHH2(member, f1List, f2List);
  const { hh3, hh4 } = calculateHH3AndHH4(member, f1List);
  
  const total = hh1 + hh2 - hh3 + hh4;
  
  console.log('\nKết quả:');
  console.log(`HH1: ${hh1.toLocaleString()}`);
  console.log(`HH2: ${hh2.toLocaleString()}`);
  console.log(`HH3: ${hh3.toLocaleString()}`);
  console.log(`HH4: ${hh4.toLocaleString()}`);
  console.log(`Tổng = ${hh1.toLocaleString()} + ${hh2.toLocaleString()} - ${hh3.toLocaleString()} + ${hh4.toLocaleString()} = ${total.toLocaleString()}`);
  
  return { hh1, hh2, hh3, hh4, total };
};

module.exports = {
  calculateCommission,
  calculateBranchSales,
  getCommissionRate
};

