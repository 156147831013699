const PRODUCT_NAME = "Bữa ăn lành mạnh"; 
const PRODUCT_PRICE = 595000; 
const PRODUCT_PV = 205;
const PRODUCT_IMAGE_URL = "https://lotilife.com/wp-content/uploads/2024/08/IMG_2152-1-300x300.jpg";
const dataTree =  {
    key: 'F0',
    title: 'F0',
    sales: 1785000,
    pv: 615,
    commission: 0,
    children: [
      {
        key: 'F11',
        title: 'F11',
        sales: 1785000,
        pv: 615,
        commission: 0,
        children: [
          {
            key: 'F21',
            title: 'F21',
            sales: 1785000,
            pv: 615,
            commission: 0,
            children: [
              {
                key: 'F31',
                title: 'F31',
                sales: 1785000,
                pv: 615,
                commission: 0,
                children: [
                  {
                    key: 'F41',
                    title: 'F41',
                    sales: 1785000,
                    pv: 615,
                    commission: 0,
                    children: [
                      {
                        key: 'F51',
                        title: 'F51',
                        sales: 5950000,
                        pv: 615,
                        commission: 0
                      },
                      {
                        key: 'F52',
                        title: 'F52',
                        sales: 1785000,
                        pv: 615,
                        commission: 0
                      },
                      {
                        key: 'F53',
                        title: 'F53',
                        sales: 1785000,
                        pv: 615,
                        commission: 0
                      }
                    ]
                  },
                  {
                    key: 'F42',
                    title: 'F42',
                    sales: 1785000,
                    pv: 615,
                    commission: 0,
                    children: [
                      {
                        key: 'F54',
                        title: 'F54',
                        sales: 1785000,
                        pv: 615,
                        commission: 0
                      },
                      {
                        key: 'F55',
                        title: 'F55',
                        sales: 1785000,
                        pv: 615,
                        commission: 0
                      },
                      {
                        key: 'F56',
                        title: 'F56',
                        sales: 1785000,
                        pv: 615,
                        commission: 0
                      }
                    ]
                  },
                  {
                    key: 'F43',
                    title: 'F43',
                    sales: 29155000,
                    pv: 615,
                    commission: 0,
                    children: [
                      {
                        key: 'F57',
                        title: 'F57',
                        sales: 5950000,
                        pv: 615,
                        commission: 0,
                        children: [
                          {
                            key: 'F61',
                            title: 'F61',
                            sales: 4760000,
                            pv: 615,
                            commission: 0,
                            children: [
                              {
                                key: 'F71',
                                title: 'F71',
                                sales: 2975000,
                                pv: 615,
                                commission: 0
                              },
                              {
                                key: 'F72',
                                title: 'F72',
                                sales: 2975000,
                                pv: 615,
                                commission: 0
                              }
                            ]
                          }
                        ]
                      },
                      {
                        key: 'F58',
                        title: 'F58',
                        sales: 5950000,
                        pv: 615,
                        commission: 0,
                        children: [
                          {
                            key: 'F62',
                            title: 'F62',
                            sales: 4760000,
                            pv: 615,
                            commission: 0
                          }
                        ]
                      },
                      {
                        key: 'F59',
                        title: 'F59',
                        sales: 5950000,
                        pv: 615,
                        commission: 0,
                        children: [
                          {
                            key: 'F63',
                            title: 'F63',
                            sales: 4760000,
                            pv: 615,
                            commission: 0,
                            children: [
                              {
                                key: 'F74',
                                title: 'F74',
                                sales: 8925000,
                                pv: 615,
                                commission: 0
                              },
                              {
                                key: 'F75',
                                title: 'F75',
                                sales: 8925000,
                                pv: 615,
                                commission: 0
                              },
                              {
                                key: 'F76',
                                title: 'F76',
                                sales: 8925000,
                                pv: 615,
                                commission: 0
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                key: 'F32',
                title: 'F32',
                sales: 1785000,
                pv: 615,
                commission: 0
              },
              {
                key: 'F33',
                title: 'F33',
                sales: 29155000,
                pv: 615,
                commission: 0
              }
            ]
          },
          {
            key: 'F22',
            title: 'F22',
            sales: 1785000,
            pv: 615,
            commission: 0
          },
          {
            key: 'F23',
            title: 'F23',
            sales: 29155000,
            pv: 615,
            commission: 0
          }
        ]
      },
      {
        key: 'F12',
        title: 'F12',
        sales: 1785000,
        pv: 615,
        commission: 0
      },
      {
        key: 'F13',
        title: 'F13',
        sales: 29155000,
        pv: 615,
        commission: 0,
        children: [
          {
            key: 'F24',
            title: 'F24',
            sales: 1785000,
            pv: 615,
            commission: 0
          },
          {
            key: 'F25',
            title: 'F25',
            sales: 1785000,
            pv: 615,
            commission: 0
          }
        ]
      }
    ]
  };

export { PRODUCT_NAME, PRODUCT_PRICE, PRODUCT_PV, PRODUCT_IMAGE_URL, dataTree };