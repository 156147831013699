import React from 'react';
import { Table, Space, Button, Pagination, Input, message } from 'antd';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { formatCurrencyVND } from '../utils/utils';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const MemberTable = ({ flattenedData, onAddMemberClick, onUpdateMember }) => {
  const [pageSize, setPageSize] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [editingKey, setEditingKey] = React.useState('');

  const totalRecords = flattenedData.length;
  const currentData = flattenedData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handleSalesChange = (record, value) => {
    const newValue = parseFloat(value.replace(/,/g, '')) || 0;
    onUpdateMember(record.key, { sales: newValue });
  };

  const handlePVChange = (record, value) => {
    const newValue = parseFloat(value.replace(/,/g, '')) || 0;
    onUpdateMember(record.key, { pv: newValue });
  };

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => <span style={{ marginLeft: `${record.level * 20}px` }}>{text.split(" ")[0]}</span>,
    },
    {
      title: 'Doanh số (VNĐ)',
      dataIndex: 'sales',
      key: 'sales',
      render: (value, record) => (
        <Input
          defaultValue={value?.toLocaleString()}
          onBlur={(e) => handleSalesChange(record, e.target.value)}
          onPressEnter={(e) => {
            handleSalesChange(record, e.target.value);
            e.target.blur();
          }}
          onClick={(e) => e.target.select()}
          style={{ width: '100%' }}
        />
      ),
    },
    {
      title: 'TÊN',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => <span>{text.split(" ")[0]}</span>,
    },
    {
      title: 'Hoa Hồng',
      children: [
        {
          title: 'HH1',
          dataIndex: ['commissionDetails', 'hh1'],
          key: 'hh1',
          render: (value) => value?.toLocaleString() + '₫'
        },
        {
          title: 'HH2',
          dataIndex: ['commissionDetails', 'hh2'],
          key: 'hh2',
          render: (value) => value?.toLocaleString() + '₫'
        },
        {
          title: 'HH3',
          dataIndex: ['commissionDetails', 'hh3'],
          key: 'hh3',
          render: (value) => value?.toLocaleString() + '₫'
        },
        {
          title: 'HH4',
          dataIndex: ['commissionDetails', 'hh4'],
          key: 'hh4',
          render: (value) => value?.toLocaleString() + '₫'
        },
        {
          title: 'Tổng',
          dataIndex: 'commission',
          key: 'commission',
          render: (value) => value?.toLocaleString() + '₫'
        }
      ]
    },
    // {
    //   title: 'Điểm PV',
    //   dataIndex: 'pv',
    //   key: 'pv',
    //   render: (value, record) => (
    //     <Input
    //       defaultValue={value?.toLocaleString()}
    //       onBlur={(e) => handlePVChange(record, e.target.value)}
    //       onPressEnter={(e) => {
    //         handlePVChange(record, e.target.value);
    //         e.target.blur();
    //       }}
    //       onClick={(e) => e.target.select()}
    //       style={{ width: '100%' }}
    //     />
    //   ),
    // },
    {
      title: 'Commission',
      dataIndex: 'commission',
      key: 'commission',
      render: (text) => formatCurrencyVND(text),
    },
    {
      title: '#',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => onAddMemberClick(record.key)}
          >
            Thêm
          </Button>
        </Space>
      ),
    },
    
  ];

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const exportToExcel = () => {
    // Chuẩn bị dữ liệu cho excel
    const excelData = flattenedData.map(item => ({
      'Tên': item.title.slice(0, 2),
      'Level': item.level,
      'Doanh số': item.sales,
      'HH1': item.commissionDetails.hh1,
      'HH2': item.commissionDetails.hh2,
      'HH3': item.commissionDetails.hh3,
      'HH4': item.commissionDetails.hh4,
      'Tổng': item.commission
    }));

    // Tạo workbook mới
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excelData);

    // Tùy chỉnh độ rộng cột
    const colWidths = [
      { wch: 15 }, // Tên
      { wch: 10 }, // Level
      { wch: 15 }, // Doanh số
      { wch: 10 }, // Điểm PV
      { wch: 15 }, // Hoa hồng
    ];
    ws['!cols'] = colWidths;

    // Thêm worksheet vào workbook
    XLSX.utils.book_append_sheet(wb, ws, "Members");

    // Tạo buffer
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Tải file
    saveAs(data, `members_${new Date().toISOString().slice(0,10)}.xlsx`);
  };

  return (
    <div>
      <div style={{ marginBottom: 16, textAlign: 'right' }}>
        <Button 
          type="primary" 
          icon={<DownloadOutlined />} 
          onClick={exportToExcel}
          style={{ marginRight: 16 }}
        >
          Export Excel
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={currentData}
        pagination={false}
        rowKey="key"
        scroll={{ x: 'max-content' }}
      />
      
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalRecords}
          onChange={handlePageChange}
          showSizeChanger
        />
        <div>
          Hiện thị {pageSize} trên {totalRecords} bản ghi
        </div>
      </div>
    </div>
  );
};

export default MemberTable;
