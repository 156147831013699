import { PRODUCT_NAME, PRODUCT_PRICE, PRODUCT_PV, PRODUCT_IMAGE_URL } from '../constants';

const ProductInfo = () => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', margin: '20px' }}>
            <img 
                src={PRODUCT_IMAGE_URL} 
                alt="Product" 
                style={{ width: '100px', height: 'auto', marginRight: '20px' }} 
            />
            <div>
                <h3>{PRODUCT_NAME}</h3>
                <h4>Giá: {PRODUCT_PRICE.toLocaleString()} VNĐ</h4>
                <p>Điểm PV: {PRODUCT_PV}</p>
            </div>
        </div>
    );
  };
  export default ProductInfo;  
  